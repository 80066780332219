const ACTION_TYPES = {
  AUTH: {
    SET_STATUS: "AUTH_SET_STATUS",
    CLEAR: "AUTH_CLEAR",
    SET_TOKEN: "AUTH_SET_TOKEN",
    UNSET_TOKEN: "AUTH_UNSET_TOKEN",
    SET_RETAILER: "AUTH_SET_RETAILER",
    UNSET_RETAILER: "AUTH_UNSET_RETAILER",
    FETCH_TOKEN: "AUTH_FETCH_TOKEN"
  },
  USER: {
    SET_STATUS: "USER_SET_STATUS",
    CLEAR: "USER_CLEAR",
    SET_DATA: "USER_SET_DATA",
    UNSET_DATA: "USER_UNSET_DATA"
  },
  BRANDS: {
    SET_STATUS: "BRANDS_SET_STATUS",
    SET_DATA: "BRANDS_SET_DATA"
  },
  CATEGORIES: {
    SET_STATUS: "CATEGORIES_SET_STATUS",
    SET_DATA: "CATEGORIES_SET_DATA"
  },
  SIZES: {
    SET_STATUS: "SIZES_SET_STATUS",
    SET_DATA: "SIZES_SET_DATA"
  },
  LISTINGS: {
    SET_STATUS: "LISTINGS_SET_STATUS",
    SET_SEARCH: "LISTINGS_SET_SEARCH",
    SET_CATEGORY: "LISTINGS_SET_CATEGORY",
    RESET: "LISTINGS_RESET",
    RESET_ALL_LISTINGS: "RESET_ALL_LISTINGS",
    RESET_SPECIFIC: "LISTINGS_RESET_SPECIFIC",
    SET_DATA_ALL_LISTINGS: "SET_DATA_ALL_LISTINGS",
    SET_DATA_BY_BRAND: "LISTINGS_SET_DATA_BY_BRAND",
    SET_DATA_BY_RETAILER: "LISTINGS_SET_DATA_BY_RETAILER",
    SET_STATUS_ALL_LISTINGS: "SET_STATUS_ALL_LISTINGS",
    SET_STATUS_BY_BRAND: "LISTINGS_SET_STATUS_BY_BRAND",
    SET_STATUS_BY_RETAILER: "LISTINGS_SET_STATUS_BY_RETAILER",
    SET_STATUS_OWN_LISTINGS: "SET_STATUS_OWN_LISTINGS",
    SET_TYPE: "LISTINGS_SET_TYPE"
  },
  REQUESTS: {
    SET_STATUS: "REQUESTS_SET_STATUS",
    RESET: "REQUESTS_RESET",
    SET_DATA: "REQUESTS_SET_DATA",
    SET_BRAND_STATUS: "REQUESTS_SET_BRAND_STATUS",
    SET_TYPE: "REQUESTS_SET_TYPE"
  },
  LISTING: {
    SET_STATUS: "LISTING_SET_STATUS",
    RESET: "LISTING_RESET",
    SET_DATA: "LISTING_SET_DATA",
    SET_LISTING_EXISTS: "SET_LISTING_EXISTS",
    SET_RELATED_RETAILER_STATUS: "LISTING_SET_RELATED_RETAILER_STATUS",
    SET_RELATED_RETAILER_DATA: "LISTING_SET_RELATED_RETAILER_DATA",
    SET_RELATED_PRODUCT_STATUS: "LISTING_SET_RELATED_PRODUCT_STATUS",
    SET_RELATED_PRODUCT_DATA: "LISTING_SET_RELATED_PRODUCT_DATA",
    SET_UPDATE_REASONS: "SET_UPDATE_REASONS"
  },
  ORDERS: {
    SET_STATUS: "ORDERS_SET_STATUS",
    SET_DATA: "ORDERS_SET_DATA",
    SET_STATE: "ORDERS_SET_STATE",
    SET_TYPE: "ORDERS_SET_TYPE",
    SET_PAGE: "ORDERS_SET_PAGE"
  },
  ORDER: {
    SET_STATUS: "ORDER_SET_STATUS",
    SET_ACTION_STATUS: "ORDERS_SET_ACTION_STATUS",
    SET_DATA: "ORDER_SET_DATA",
    SET_TYPE: "ORDER_SET_TYPE"
  },
  ERROR: {
    SET_ERROR: "SET_ERROR",
    UNSET_ERROR: "UNSET_ERROR",
    CLEAR: "ERROR_CLEAR"
  },
  POPUP: {
    ADD_POPUP: "ADD_POPUP",
    REMOVE_POPUP: "REMOVE_POPUP",
    CLEAR_POPUP: "CLEAR_POPUP"
  },
  CART: {
    SET_TYPE: "SET_TYPE",
    ADD_ITEM: "ADD_ITEM",
    MODIFY_ITEM: "MODIFY_ITEM",
    REMOVE_ITEM: "REMOVE_ITEM",
    INIT_CART: "INIT_CART", // If we ever decide to store cart contents within a CRM
    SUBMIT_CART: "SUBMIT_CART",
    CLEAR_CART: "CLEAR_CART",
    SET_STATUS: "CART_SET_STATUS",
    SET_TRANSACTION: "SET_TRANSACTION",
    REMOVE_BY_RETAILER_ID: "REMOVE_BY_RETAILER_ID"
  },
  FILTERS: {
    SET_FILTERS: "SET_FILTERS",
    REMOVE_FILTER: "REMOVE_FILTER",
    CLEAR_FILTERS: "CLEAR_FILTERS",
    SET_CATEGORY: "SET_CATEGORY",
    ALL_CATEGORIES: "ALL_CATEGORIES",
    SET_STATUS: "FILTERS_SET_STATUS",
    SET_PRICE_RANGE: "SET_PRICE_RANGE",
    RESET_PRICE_RANGE: "RESET_PRICE_RANGE"
  },
  RETAILER: {
    SET_STATUS: "SET_STATUS",
    GET_RETAILER_INFO: "GET_RETAILER_INFO",
    SET_DATA: "SET_DATA"
  }
};

export { ACTION_TYPES };
