import { Box, Flex, Link as CLink } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import PopupManager from "components/popup-manager";
import { POPUP_TYPES } from "constants/popups.const";
import { getCartTotalUnits } from "store/selectors/cart";
import { Icon } from "ui-kit";
// @ts-ignore
import { Cart } from "ui-kit/icon";

export const SideNavLink = ({ children, icon, external, ...rest }: any) => {
  return (
    <Box as="li" w="full" listStyleType="none" role="group">
      <Box
        as={external ? CLink : NavLink}
        borderRadius="lg"
        color="gray.900"
        py="2"
        px="3"
        d="inline-flex"
        alignItems="center"
        w="full"
        textDecor="none"
        _hover={{ bg: "gray.100", color: "gray.800", textDecor: "none" }}
        sx={{
          "&.active": {
            bg: "gray.100"
          }
        }}
        {...rest}
      >
        {icon && (
          <Icon
            as={icon}
            boxSize="5"
            mr="2"
            mb="3px"
            sx={{ ".active &": { color: "primary" } }}
            _groupHover={{ color: "primary" }}
          />
        )}
        <span>{children}</span>
      </Box>
    </Box>
  );
};

export const SideNavCartLink = () => {
  const cartUnits = useSelector(getCartTotalUnits);

  if (!cartUnits) return null;

  return (
    <SideNavLink to="/cart" role="group">
      <Box d={{ base: "none", lg: "initial" }}>
        <PopupManager filters={[POPUP_TYPES.CART]} />
      </Box>
      <Flex d="inline-flex" w="full" align="center">
        <Box as="span" mr="auto">
          Cart ({cartUnits})
        </Box>
        <Icon as={Cart} sx={{ ".active &": { color: "primary" } }} _groupHover={{ color: "primary" }} />
      </Flex>
    </SideNavLink>
  );
};
